body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.screen-reader-content {
  position: absolute;
  left: -999px;
}

.cells-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
}

.cell {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: calc(100% / 9);
}

.cell p {
  margin: 0;
  padding: 0;
  font-size: 102px;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 102px;
  color: #cbd2d0;
  user-select: none;
}

.cell svg {
  height: 100%;
  fill: #cbd2d0;
  margin-top: 2px;
}

a {
  height: calc(100% - 26px);
  text-decoration: none;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(../assets/bg-3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(11, 60, 73, 0.5); */
  /* background-color: rgba(115, 25, 99, 0.5); */
  /* background: rgb(252, 176, 69);
  background: linear-gradient(
    180deg,
    rgba(252, 176, 69, 0.65) 0%,
    rgba(253, 29, 29, 0.65) 50%,
    rgba(115, 25, 99, 0.65) 100%
  ); */
  background: rgb(253, 106, 29);
  background: linear-gradient(
    180deg,
    rgba(253, 106, 29, 0.4) 0%,
    rgba(115, 25, 99, 0.65) 100%
  );
}

@media screen and (max-width: 950px) {
  .cell {
    width: calc(90vw / 9);
    height: calc(90vw / 9);
  }

  .cell a {
    width: calc(90vw / 9 - 2.6vw);
    height: calc(90vw / 9 - 2.6vw);
  }
  .cell svg {
    margin: 0;
  }
  .cell p {
    font-size: calc(90vw / 9);
    line-height: calc(90vw / 9);
  }
}
